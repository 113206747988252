import React from 'react';
import './project.css';
import Nav from '../layout/Nav';
import Footer from '../layout/Footer';
import Demo from './Demo';
const Project = () => {
  return (
    <>
      <Nav />
      <div class='main-container'>
        <div class='heading'>
          <h1 class='heading__title'>Project</h1>
          <p class='heading__credits'>
            <a class='heading__link' href='#1'>
              Design by Nayem Islam on Dribbble
            </a>
          </p>
        </div>

        <div class='cards'>
          <div class='card card-1'>
            <div class='card__icon'>
              <i class='fas fa-bolt'></i>
            </div>
            <p class='card__exit'>
              <i class='fas fa-times'></i>
            </p>
            <h2 class='card__title'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h2>
            <p class='card__apply '>
              <a class='card__link m-5' href='#demo1'>
                Live Demo <i class='fas fa-arrow-right'></i>
              </a>
              <a class='card__link m-5' href='#demo1'>
                View Details <i class='fas fa-arrow-right'></i>
              </a>
            </p>
          </div>

          <div class='card card-2'>
            <div class='card__icon'>
              <i class='fas fa-bolt'></i>
            </div>
            <p class='card__exit'>
              <i class='fas fa-times'></i>
            </p>
            <h2 class='card__title'>
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h2>
            <p class='card__apply'>
              <a class='card__link' href='#demo2'>
                Live Demo <i class='fas fa-arrow-right'></i>
              </a>
            </p>
          </div>

          <div class='card card-3'>
            <div class='card__icon'>
              <i class='fas fa-bolt'></i>
            </div>
            <p class='card__exit'>
              <i class='fas fa-times'></i>
            </p>
            <h2 class='card__title'>Ut enim ad minim veniam.</h2>
            <p class='card__apply'>
              <a class='card__link' href='#demo3'>
                Live Demo <i class='fas fa-arrow-right'></i>
              </a>
            </p>
          </div>

          <div class='card card-4'>
            <div class='card__icon'>
              <i class='fas fa-bolt'></i>
            </div>
            <p class='card__exit'>
              <i class='fas fa-times'></i>
            </p>
            <h2 class='card__title'>
              Quis nostrud exercitation ullamco laboris nisi.
            </h2>
            <p class='card__apply'>
              <a class='card__link' href='#demo4'>
                Live Demo <i class='fas fa-arrow-right'></i>
              </a>
            </p>
          </div>

          <div class='card card-5'>
            <div class='card__icon'>
              <i class='fas fa-bolt'></i>
            </div>
            <p class='card__exit'>
              <i class='fas fa-times'></i>
            </p>
            <h2 class='card__title'>
              Ut aliquip ex ea commodo consequat. Duis aute irure dolor.
            </h2>
            <p class='card__apply'>
              <a class='card__link' href='#demo5'>
                Live Demo <i class='fas fa-arrow-right'></i>
              </a>
            </p>
          </div>

          <div class='card card-1'>
            <div class='card__icon'>
              <i class='fas fa-bolt'></i>
            </div>
            <p class='card__exit'>
              <i class='fas fa-times'></i>
            </p>
            <h2 class='card__title'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h2>
            <p class='card__apply'>
              <a class='card__link' href='#demo6'>
                Live Demo <i class='fas fa-arrow-right'></i>
              </a>
            </p>
          </div>
        </div>
      </div>

      <Demo />

      <Footer />
    </>
  );
};

export default Project;

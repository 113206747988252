import React from "react";
import { Link } from "react-router-dom";
import "./nav.css";

const Nav = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-dark sticky-top"
        style={{ backgroundColor: "#19B99D" }}>
        <div className="container-fluid">
          {/* navbar brand */}
          <a className="navbar-brand" href="#d">
            <img
              src="/image/easyCode.png"
              alt="logo"
              className="p-0 nav-logo"
              width={100}
            />
          </a>
          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active text-light"
                  aria-current="page"
                  to="/">
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light" to="/blog">
                  BLOG
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light" to="/project">
                  PROJECT
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light" to="/About">
                  ABOUT
                </Link>
              </li>
            </ul>
            <span class="navbar-text">
              <i className="m-2 bi bi-github"></i>
              <i className="m-2 bi bi-linkedin"></i>
              <i className="m-2 bi bi-twitter"></i>
              <i className="m-2 bi bi-facebook"></i>
              <i className="m-2 bi bi-instagram"></i>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;

import React from 'react';
import Demo from '../page/Demo';

import './main.css';

const Main = () => {
  return (
    <>
      {/*Header section */}
      <section className='container-fluid text-center text-sm-start container-body-1 py-md-5'>
        <div className=''>
          <div className='container d-sm-flex align-items-center justify-content-between'>
            <div className='border rounded'>
              <img
                src='/image/n.jpg'
                style={{ backgroundColor: '#CCFFFF' }}
                className='border-dark img-thumbnail img-fluid rounded'
                alt='...'
                width={260}
              />
            </div>
            <article className='col-sm-9 p-sm-2'>
              <header>
                <h1>
                  Hi i am <span className='text-primary'>Nayem Islam</span>
                </h1>
              </header>

              <main>
                <h4 className='text'>Web development & Designer</h4>
                <p className='lead my-3 text-dark'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi quo commodi illo placeat dolorum libero inventore
                  eius nobis culpa beatae iusto consequatur nulla quia
                  consequuntur sed accusamus, eos reiciendis quod! Deleniti
                  adipisci perferendis quos quidem placeat et voluptatum
                  dignissimos! adipisci perferendis quos quidem placeat et
                  voluptatum dignissimos!
                </p>
              </main>

              <footer>
                <button className='btn btn-outline-dark btn-sm '>
                  Hire me
                </button>
                <button className='btn btn-dark  btn-sm m-2'>Get cv</button>
              </footer>
            </article>
          </div>
        </div>
      </section>

      <section>
        <div class='container-fluid bg-dark py-5'>
          <div class='row'>
            <div class='col-md-3'>
              <div class='card-counter mx-auto p-5 d-flex align-items-center'>
                <i
                  className=' bi bi-link text-dark'
                  style={{ fontSize: '50px' }}
                ></i>
                <span class='count-numbers'>6-</span>
                <span class='count-name'>Project</span>
              </div>
            </div>

            <div class='col-md-3'>
              <div class='card-counter p-5 d-flex align-items-center'>
                <i
                  className=' bi bi-link text-dark'
                  style={{ fontSize: '50px' }}
                ></i>
                <span class='count-numbers'>599</span>
                <span class='count-name '>Instances</span>
              </div>
            </div>

            <div class='col-md-3'>
              <div class='card-counter p-5 d-flex align-items-center'>
                <i
                  className='m-2 bi bi-github text-dark'
                  style={{ fontSize: '50px' }}
                ></i>
                <i class='fa fa-database '></i>
                <span class='count-numbers'>6875</span>
                <span class='count-name'>Data</span>
              </div>
            </div>

            <div class='col-md-3'>
              <div class='card-counter p-5 d-flex align-items-center'>
                <i
                  className='m-2 bi bi-tree text-dark'
                  style={{ fontSize: '50px' }}
                ></i>
                <span class='count-numbers'>35</span>
                <span class='count-name'>Users</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* service */}
      <section className='text-center text-sm-start container-body-2 py-5'>
        <div className='container'>
          <div className='d-sm-flex align-items-center justify-content-between'>
            <div className=''>
              <img src='image/bg-svg.png' alt='' className='' width={300} />
            </div>
            <div className='col-sm-9'>
              <div>
                <h1 className='text-white '>Service</h1>
                <h4 className='text-dark'>Web development & Designer</h4>
                <p className='lead '>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi quo commodi illo placeat dolorum libero inventore
                  eius nobis culpa beatae iusto consequatur nulla quia
                  consequuntur sed accusamus, eos reiciendis quod! Deleniti
                  adipisci perferendis quos quidem placeat et voluptatum
                  dignissimos! adipisci perferendis quos quidem placeat et
                  voluptatum dignissimos!
                </p>
              </div>
              <div>
                <button className='btn btn-outline-dark btn-sm'>Views</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* skill */}
      <section className='text-center text-sm-end container-body-3'>
        <div className='container py-md-5'>
          <div className='d-sm-flex align-items-center justify-content-between'>
            <div className='col-sm-9'>
              <div>
                <h1 className='text-info'>My Skill</h1>
                <h4>Web development & Designer</h4>
                <p className='lead'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi quo commodi illo placeat dolorum libero inventore
                  eius nobis culpa beatae iusto consequatur nulla quia
                  consequuntur sed accusamus, eos reiciendis quod! Deleniti
                  adipisci perferendis quos quidem placeat et voluptatum
                  dignissimos! adipisci perferendis quos quidem placeat et
                  voluptatum dignissimos!
                </p>
              </div>
              <div>
                <button className='btn btn-outline-dark btn-sm'>Views</button>
              </div>
            </div>

            <div className='border-2 rounded'>
              <img
                src='/image/f.png'
                style={{ backgroundColor: '#CCFFFF' }}
                className='border-dark object-fit-contain img-fluid rounded p-md-1'
                alt='...'
                width={250}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class='container-fluid mx-auto mt-5 mb-5 text-center'>
          <div class='hd'>Why People Believe in Us</div>
          <p>
            <small class='text-muted'>
              Always render more and better service than <br />
              is expected of you, no matter what your ask may be.
            </small>
          </p>

          <div class='row justify-content-center'>
            <div class='card col-md-6 cardsContent-1'>
              <div class='card-content '>
                <div class='card-body  '>
                  <img
                    class='img'
                    src='https://i.imgur.com/S7FJza5.png'
                    alt='..'
                    width={40}
                  />
                  <div class='shadow'></div>
                  <div class='card-title'> We're Free </div>
                  <div class='card-subtitle'>
                    <p>
                      <small class='text-muted'>
                        We spent thousands of hours creating on algorithm that
                        does this for you in seconds. We collect a small fee
                        from the professional after they meet your
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class='card col-md-6 cardsContent-2'>
              <div class='card-content'>
                <div class='card-body'>
                  <img
                    class='img'
                    src='https://i.imgur.com/xUWJuHB.png'
                    alt='..'
                    width={40}
                  />
                  <div class='card-title'> We're Unbiased </div>
                  <div class='card-subtitle'>
                    <p>
                      <small class='text-muted'>
                        We don't accept ads from anyone. We use actual data to
                        match you who the best person for each job
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class='card col-md-6 cardsContent-3'>
              <div class='card-content'>
                <div class='card-body'>
                  <img
                    class='img rck'
                    src='https://i.imgur.com/rG3CGn3.png'
                    width={40}
                    alt='..'
                  />
                  <div class='card-title'> We Guide you </div>
                  <div class='card-subtitle'>
                    <p>
                      <small class='text-muted'>
                        Buying or selling a home is often the largest
                        transaction anyone does in their life. we guide you
                        through the process so that you can be confident in
                        reaching your dream outcome.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class='card col-md-6 cardsContent-4'>
              <div class='card-content'>
                <div class='card-body'>
                  <img
                    class='img'
                    src='https://i.imgur.com/S7FJza5.png'
                    alt='..'
                    width={40}
                  />
                  <div class='shadow'></div>
                  <div class='card-title'> We're Free </div>
                  <div class='card-subtitle'>
                    <p>
                      <small class='text-muted'>
                        We spent thousands of hours creating on algorithm that
                        does this for you in seconds. We collect a small fee
                        from the professional after they meet your
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='ft'>
            <p class='chk'>
              <small class='text-muted'>Still not sure?</small>
            </p>
            <div class='btn btn-primary'>Read All Testimonials</div>
          </div>
        </div>
      </section>

      <section>
        {/* <img src='image/d.gif' alt='' className='img-fluid' width='100%' /> */}
        <Demo />
      </section>
    </>
  );
};

export default Main;

import React from 'react';
import './blog.css';
import Nav from '../layout/Nav';
import Footer from '../layout/Footer';

const Blog = () => {
  return (
    <>
      <Nav />
      <div className='container mt-5 mb-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex flex-row justify-content-between align-items-center filters'>
              <h6>Showing 291 tasks</h6>
              <div className='right-sort'>
                <div className='sort-by'>
                  <span className='mr-1'>Sort by:</span>
                  <a href='#d'>Most popular</a>
                  <i className='fa fa-angle-down ml-1'></i>
                  <button
                    className='btn btn-outline-dark btn-sm ml-3 filter'
                    type='button'
                  >
                    Filters&nbsp;<i className='fa fa-flask'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-1'>
          <div className='col-md-4'>
            <div className='p-card bg-white p-2 rounded px-3'>
              <div className='d-flex align-items-center credits'>
                <img
                  src='https://i.imgur.com/hlz6G90.png'
                  width='16px'
                  alt='..'
                />
                <span className='text-black-50 ml-2'>1 credits</span>
              </div>
              <h5 className='mt-2'>
                Be social - Sell your stock directly on instagram
              </h5>
              <span className='badge badge-danger py-1 mb-2'>
                Marketing &amp; Sales
              </span>
              <span className='d-block mb-5'>
                Some kind of short descriptions can go here to better recommend
                tasks.
              </span>
              <div className='d-flex justify-content-between stats'>
                <div>
                  <i className='fa fa-calendar-o'></i>
                  <span className='ml-2'>1 days ago</span>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div className='profiles'>
                    <img
                      className='rounded-circle'
                      src='https://i.imgur.com/4nUVGjW.jpg'
                      width='30'
                      alt='...'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src=' https://i.imgur.com/GHCtqgp.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/UL0GS75.jpg'
                      width='30'
                    />
                  </div>
                  <span className='ml-3'>12</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='p-card bg-white p-2 rounded px-3'>
              <div className='d-flex align-items-center credits'>
                <img
                  alt='...'
                  src='https://i.imgur.com/hlz6G90.png'
                  width='16px'
                />
                <span className='text-black-50 ml-2'>2 credits</span>
              </div>
              <h5 className='mt-2'>
                Increase confidence with trustpilot reviews
              </h5>
              <span className='badge badge-primary py-1 mb-2'>
                integrations
              </span>
              <span className='d-block mb-5'>
                Some kind of short descriptions can go here to better recommend
                tasks.
              </span>
              <div className='d-flex justify-content-between stats'>
                <div>
                  <i className='fa fa-calendar-o'></i>
                  <span className='ml-2'>2 days ago</span>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div className='profiles'>
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/KcxKRBm.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/DukIAef.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/UL0GS75.jpg'
                      width='30'
                    />
                  </div>
                  <span className='ml-3'>16</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='p-card bg-white p-2 rounded px-3'>
              <div className='d-flex align-items-center credits'>
                <img
                  alt='...'
                  src='https://i.imgur.com/hlz6G90.png'
                  width='16px'
                />
                <span className='text-black-50 ml-2'>1 credits</span>
              </div>
              <h5 className='mt-2'>
                Be social - Sell your stock directly on instagram
              </h5>
              <span className='badge badge-danger py-1 mb-2'>
                Marketing &amp; Sales
              </span>
              <span className='d-block mb-5'>
                Some kind of short descriptions can go here to better recommend
                tasks.
              </span>
              <div className='d-flex justify-content-between stats'>
                <div>
                  <i className='fa fa-calendar-o'></i>
                  <span className='ml-2'>1 days ago</span>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div className='profiles'>
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/4nUVGjW.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src=' https://i.imgur.com/GHCtqgp.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/UL0GS75.jpg'
                      width='30'
                    />
                  </div>
                  <span className='ml-3'>12</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='p-card bg-white p-2 rounded px-3'>
              <div className='d-flex align-items-center credits'>
                <img
                  alt='...'
                  src='https://i.imgur.com/hlz6G90.png'
                  width='16px'
                />
                <span className='text-black-50 ml-2'>2 credits</span>
              </div>
              <h5 className='mt-2'>
                Increase confidence with trustpilot reviews
              </h5>
              <span className='badge badge-primary py-1 mb-2'>
                integrations
              </span>
              <span className='d-block mb-5'>
                Some kind of short descriptions can go here to better recommend
                tasks.
              </span>
              <div className='d-flex justify-content-between stats'>
                <div>
                  <i className='fa fa-calendar-o'></i>
                  <span className='ml-2'>2 days ago</span>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div className='profiles'>
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/KcxKRBm.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/DukIAef.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/UL0GS75.jpg'
                      width='30'
                    />
                  </div>
                  <span className='ml-3'>16</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='p-card bg-white p-2 rounded px-3'>
              <div className='d-flex align-items-center credits'>
                <img
                  alt='...'
                  src='https://i.imgur.com/hlz6G90.png'
                  width='16px'
                />
                <span className='text-black-50 ml-2'>1 credits</span>
              </div>
              <h5 className='mt-2'>
                Be social - Sell your stock directly on instagram
              </h5>
              <span className='badge badge-danger py-1 mb-2'>
                Marketing &amp; Sales
              </span>
              <span className='d-block mb-5'>
                Some kind of short descriptions can go here to better recommend
                tasks.
              </span>
              <div className='d-flex justify-content-between stats'>
                <div>
                  <i className='fa fa-calendar-o'></i>
                  <span className='ml-2'>1 days ago</span>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div className='profiles'>
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/4nUVGjW.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src=' https://i.imgur.com/GHCtqgp.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/UL0GS75.jpg'
                      width='30'
                    />
                  </div>
                  <span className='ml-3'>12</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='p-card bg-white p-2 rounded px-3'>
              <div className='d-flex align-items-center credits'>
                <img
                  alt='...'
                  src='https://i.imgur.com/hlz6G90.png'
                  width='16px'
                />
                <span className='text-black-50 ml-2'>2 credits</span>
              </div>
              <h5 className='mt-2'>
                Increase confidence with trustpilot reviews
              </h5>
              <span className='badge badge-primary py-1 mb-2'>
                integrations
              </span>
              <span className='d-block mb-5'>
                Some kind of short descriptions can go here to better recommend
                tasks.
              </span>
              <div className='d-flex justify-content-between stats'>
                <div>
                  <i className='fa fa-calendar-o'></i>
                  <span className='ml-2'>2 days ago</span>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div className='profiles'>
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/KcxKRBm.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/DukIAef.jpg'
                      width='30'
                    />
                    <img
                      alt='...'
                      className='rounded-circle'
                      src='https://i.imgur.com/UL0GS75.jpg'
                      width='30'
                    />
                  </div>
                  <span className='ml-3'>16</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end text-right mt-2'>
          <nav>
            <ul className='pagination'>
              <li className='page-item'>
                <a className='page-link' href='#5' aria-label='Previous'>
                  <span aria-hidden='true'>&laquo;</span>
                </a>
              </li>
              <li className='page-item'>
                <a className='page-link' href='#5'>
                  1
                </a>
              </li>
              <li className='page-item'>
                <a className='page-link' href='#5'>
                  2
                </a>
              </li>
              <li className='page-item'>
                <a className='page-link' href='#5'>
                  3
                </a>
              </li>
              <li className='page-item'>
                <a className='page-link' href='#5'>
                  4
                </a>
              </li>
              <li className='page-item'>
                <a className='page-link' href='#5'>
                  5
                </a>
              </li>
              <li className='page-item'>
                <a className='page-link' href='#5' aria-label='Next'>
                  <span aria-hidden='true'>&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;

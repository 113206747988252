import React from "react";
import Footer from "../layout/Footer";
import Main from "../layout/Main";
import Nav from "../layout/Nav";
const Home = () => {
  return (
    <>
      <Nav />
      <Main />
      <Footer />
    </>
  );
};

export default Home;

import React from "react";
import "./footer.css";
import {
  FbIcon,
  GithubIcon,
  SendIcon,
  InstagramIcon,
  LinkedinIcon,
} from "../style/icon";
const Footer = () => {
  return (
    <>
      <footer className="w-100 py-4 flex-shrink-0 bg-dark ">
        <div className="container py-4 ">
          <div className="row gy-4 gx-5">
            <div className="col-lg-4 col-md-6">
              <div className="my-3">
                <span className="p-2">
                  <FbIcon />
                </span>
                <span className="p-2">
                  <GithubIcon />
                </span>
                <span className="p-2">
                  <InstagramIcon />
                </span>
                <span className="p-2">
                  <LinkedinIcon />
                </span>
              </div>
              <p className="small text-white ">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
              <h5 className="small text-light ">
                &copy; Copyrights. All rights reserved.
                <a className="text-info" href="#nayem">
                  nayemislam.com
                </a>
              </h5>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-white mb-3">Quick links</h5>
              <ul className="list-unstyled ">
                <li>
                  <a href="#5">Home</a>
                </li>
                <li>
                  <a href="#5">About</a>
                </li>
                <li>
                  <a href="#5">Get started</a>
                </li>
                <li>
                  <a href="#5">FAQ</a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-2 col-md-6"
              style={{ textDecoration: "none" }}>
              <h5 className="text-white mb-3">Quick links</h5>
              <ul className="list-unstyled ">
                <li>
                  <a href="#5">Home</a>
                </li>
                <li>
                  <a href="#5">About</a>
                </li>
                <li>
                  <a href="#5">Get started</a>
                </li>
                <li>
                  <a href="#5">FAQ</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 text-white">
              <h5 className="text-white mb-3">Newsletter</h5>
              <p className="small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
              <form action="#">
                <div className="input-group mb-3 ">
                  <input
                    className="form-control bg-white"
                    type="text"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <button
                    className="btn btn-primary"
                    id="button-addon2"
                    type="button">
                    <SendIcon />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./page/Home";
import Blog from "./page/Blog";
import About from "./page/About";
import Project from "./page/Project";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/project" element={<Project />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React from 'react';
import './demo.css';

const Demo = () => {
  return (
    <>
      <div class='container mt-40 my-5'>
        <h3 class='text-center'>Hover Effect Style : Demo - 5</h3>
        <div class='row mt-30'>
          <div class='col-md-4 col-sm-6'>
            <div class='box5'>
              <img
                class='pic-1'
                src='https://www.w3schools.com/bootstrap4/img_avatar3.png'
                alt='...'
              />
              <ul class='icon'>
                <li>
                  <a href='lokaloka' class='fa fa-search'></a>
                </li>
                <li>
                  <a href='lokaloka' class='fa fa-link'></a>
                </li>
              </ul>
              <div class='box-content'>
                <h3 class='title'>Williamson</h3>
                <span class='post'>Web Developer</span>
              </div>
            </div>
          </div>
          <div class='col-md-4 col-sm-6'>
            <div class='box5'>
              <img
                class='pic-1'
                src='https://www.w3schools.com/bootstrap4/img_avatar4.png'
                alt='...'
              />
              <ul class='icon'>
                <li>
                  <a href='lokaloka' class='fa fa-search'></a>
                </li>
                <li>
                  <a href='lokaloka' class='fa fa-link'></a>
                </li>
              </ul>
              <div class='box-content'>
                <h3 class='title'>Kristiana</h3>
                <span class='post'>Web Designer</span>
              </div>
            </div>
          </div>
          <div class='col-md-4 col-sm-6'>
            <div class='box5'>
              <img
                class='pic-1'
                src='https://www.w3schools.com/bootstrap4/img_avatar5.png'
                alt='...'
              />
              <ul class='icon'>
                <li>
                  <a href='lokaloka' class='fa fa-search'></a>
                </li>
                <li>
                  <a href='lokaloka' class='fa fa-link'></a>
                </li>
              </ul>
              <div class='box-content'>
                <h3 class='title'>Kristiana</h3>
                <span class='post'>Web Designer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
